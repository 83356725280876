export default [
  {
    header: 'Sales',
    icon: 'SmileIcon',
    i18n: 'Sales',
    items: [
      {
        url: '/sales/quotes',
        name: 'Quotes',
        slug: 'sales-quotes',
        icon: 'FileIcon',
        i18n: 'Quotes'
      },
      {
        url: '/sales/purchases',
        name: 'Purchases',
        slug: 'sales-purchases',
        icon: 'DollarSignIcon',
        i18n: 'Purchases'
      },
      {
        url: '/sales/guides',
        name: 'Guides',
        slug: 'sales-guides',
        icon: 'TruckIcon',
        i18n: 'Guides'
      },
      {
        url: '/sales/invoices',
        name: 'Invoices',
        slug: 'sales-invoices',
        icon: 'DollarSignIcon',
        i18n: 'Invoices'
      },
      {
        url: '/sales/credits',
        name: 'Credits',
        slug: 'sales-credits',
        icon: 'XIcon',
        i18n: 'Credits'
      }
    ]
  },
  {
    header: 'ProviderPurchases',
    icon: 'SmileIcon',
    i18n: 'ProviderPurchases',
    items: [
      {
        url: '/purchases/invoices',
        name: 'Invoices',
        slug: 'purchases-invoices',
        icon: 'DollarSignIcon',
        i18n: 'Invoices'
      },
    ]
  },
  {
    header: 'Clients',
    icon: 'SmileIcon',
    i18n: 'Clients',
    items: [
      {
        url: '/clients/list',
        name: 'List',
        slug: 'clients',
        icon: 'SmileIcon',
        i18n: 'List'
      },
      {
        url: '/clients/addresses',
        name: 'ClientsAddresses',
        slug: 'addresses',
        icon: 'MapIcon',
        i18n: 'Addresses'
      },
      {
        url: '/clients/contacts',
        name: 'ClientsContact',
        slug: 'contacts',
        icon: 'BookIcon',
        i18n: 'Contacts'
      }
    ]
  },
  {
    header: 'Providers',
    icon: 'SmileIcon',
    i18n: 'Providers',
    items: [
      {
        url: '/providers/list',
        name: 'List',
        slug: 'clients',
        icon: 'SmileIcon',
        i18n: 'List'
      },
      {
        url: '/providers/addresses',
        name: 'ClientsAddresses',
        slug: 'addresses',
        icon: 'MapIcon',
        i18n: 'Addresses'
      },
      {
        url: '/providers/contacts',
        name: 'ClientsContact',
        slug: 'contacts',
        icon: 'BookIcon',
        i18n: 'Contacts'
      }
    ]
  },
  {
    header: 'Warehouse',
    icon: 'PackageIcon',
    i18n: 'Warehouse',
    items: [
      {
        url: '/warehouse/products',
        name: 'Products',
        slug: 'products',
        icon: 'StarIcon',
        i18n: 'Products'
      },
      {
        url: '/warehouse/warehouses',
        name: 'Warehouses',
        slug: 'warehouses',
        icon: 'BoxIcon',
        i18n: 'Warehouses'
      },
      {
        url: '/warehouse/vehicles',
        name: 'Vehicles',
        slug: 'vehicles',
        icon: 'TruckIcon',
        i18n: 'Vehicles'
      }
    ]
  },
  {
    header: 'Movements',
    icon: 'TruckIcon',
    i18n: 'Movements',
    items: [
      {
        url: '/warehouse/inputs',
        name: 'Inputs',
        slug: 'inputs',
        icon: 'PlusIcon',
        i18n: 'Inputs'
      },
      {
        url: '/warehouse/outputs',
        name: 'Outputs',
        slug: 'outputs',
        icon: 'MinusIcon',
        i18n: 'Outputs'
      },
      {
        url: '/warehouse/transfers',
        name: 'Transfers',
        slug: 'transfers',
        icon: 'Maximize2Icon',
        i18n: 'Transfers'
      },
      {
        url: '/warehouse/movements',
        name: 'Movements',
        slug: 'movements',
        icon: 'EyeIcon',
        i18n: 'Movements'
      },
      {
        url: '/warehouse/queries',
        name: 'Queries',
        slug: 'queries',
        icon: 'SearchIcon',
        i18n: 'Queries'
      }
    ]
  },
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
    items: [
      {
        url: '/settings/users',
        name: 'Users',
        slug: 'users',
        icon: 'SettingsIcon',
        i18n: 'Users'
      },
      {
        url: '/settings/content',
        name: 'General',
        slug: 'content',
        icon: 'AlignCenterIcon',
        i18n: 'General'
      }
    ]
  }
]

